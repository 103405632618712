export default {
    name: "tools-disclaimer",
    components: {},
    props: {
        title: {
            type: String,
            default: `Potential Results`,
        },
        // description: {
        //     type: String,
        //     default: `Take it with a grain of salt.`,
        // },
    },
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {},
};
