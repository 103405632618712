import { render, staticRenderFns } from "./tools-disclaimer.html?vue&type=template&id=7d3b03be&scoped=true&"
import script from "./tools-disclaimer.js?vue&type=script&lang=js&"
export * from "./tools-disclaimer.js?vue&type=script&lang=js&"
import style0 from "./tools-disclaimer.scss?vue&type=style&index=0&id=7d3b03be&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d3b03be",
  null
  
)

export default component.exports