<template>
    <div class="tw-rounded-lg tw-bg-white tw-shadow-md tw-overflow-hidden twiiter_profile_tweets">
        <!-- Details tab -->
        <div v-if="userDetails.pk && isSearchClicked" class="instagram_profile profile">
            <div class="tw-w-full tw-self-center tw-relative">
                <!-- MONITORING STATUS  -->
                <template v-if="isMonitoringTab">
                    <div class="tw-flex tw-absolute tw-top-0 tw-left-0 tw-mr-2 tw-items-center tw-py-1 tw-text-xs tw-px-3 tw-rounded-br-lg tw-capitalize" :class="getBackgroundStatus(userDetails.monitoring_result_status)">
                        {{ userDetails.monitoring_result_status }}
                    </div>
                    <div class="tw-flex tw-pt-3 tw-px-3 tw-items-center tw-justify-end">
                        <neo-switch class="tw-mr-4 tw-m-0" :status="userDetails.monitoring_status" @click="handleMonitoring"></neo-switch>
                        <label for="mark_as_done" @click.stop="" v-tippy content="Mark as Acknowledged" class="tw-mx-0">
                            <input type="checkbox" id="mark_as_done" class="tw-form-checkbox tw-m-0 tw-cursor-pointer tw-h-5 tw-w-5 tw-text-gray-600" :checked="userDetails.changes_viewed" @change="handleDone($event)" />
                        </label>
                    </div>
                    <div class="tw-absolute tw-bottom-2 tw-left-1">
                        <span class="tw-items-center tw-px-2 tw-py-0.5 tw-rounded tw-text-xs tw-font-medium tw-bg-blue-100 tw-text-blue-700 tw-ml-auto tw-mt-2 tw-capitalize">
                            {{ getFormattedTime(userDetails.monitoringDate) }}
                        </span>
                    </div>
                </template>

                <div class="tw-flex tw-p-8 tw-relative">
                    <!-- Profile image -->
                    <div>
                        <!-- <img referrerpolicy="no-referrer" crossorigin="" v-if="userDetails.profile_pic_url" class="tw-w-40 tw-h-40 tw-rounded-full" :id="`profile_pic_insta_${index}`" :src="getURL(userDetails.profile_pic_url, `profile_pic_insta_${index}`)" /> -->
                        <ImagePlaceholder referrerpolicy="no-referrer" crossorigin="" v-if="userDetails.profile_pic_url" :id="`profile_pic_insta_${index}`" :source="getURL(userDetails.profile_pic_url, `profile_pic_insta_${index}`)" class="tw-w-40 tw-h-40 tw-rounded-full" />
                        <font-awesome-icon icon="user-circle" class="tw-w-20 tw-h-20" color="#9ea1ad" size="5x" v-else />
                    </div>

                    <!-- User name, handle, description -->
                    <div class="tw-pt-0 tw-pl-4 tw-text-base tw-w-full">
                        <div class="tw-w-full tw-mb-2">
                            <div class="tw-flex tw-items-center tw-space-x-4">
                                <div class="tw-text-xl tw-font-medium tw-text-gray-700">{{ userDetails.username }}</div>
                                <neo-analyse v-if="userDetails.username" source="username" :value="userDetails.username" />
                                <svg v-if="userDetails.is_verified" xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-text-blue-600" viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                        fill-rule="evenodd"
                                        d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                            <div class="tw-text-lg tw-mt-1 tw-flex tw-items-center tw-space-x-4">
                                <span> {{ userDetails.full_name }}</span>
                                <neo-analyse v-if="userDetails.full_name" source="name" :value="userDetails.full_name" />
                            </div>
                            <div class="tw-text-base tw-mt-1 tw-flex tw-items-center tw-space-x-4">Email: {{ userDetails.public_email }}</div>
                            <div class="tw-text-base tw-mt-1 tw-flex tw-items-center tw-space-x-4">Instagram User ID: {{ userDetails.pk }}</div>
                            <div class="tw-mb-4 tw-mt-2 tw-flex tw-space-x-8">
                                <span v-if="userDetails.media_count >= 0">
                                    <strong> {{ userDetails.media_count }} </strong> posts
                                </span>
                                <span v-if="userDetails.follower_count >= 0">
                                    <strong> {{ userDetails.follower_count }} </strong> followers
                                </span>
                                <span v-if="userDetails.following_count >= 0">
                                    <strong> {{ userDetails.following_count }} </strong> following
                                </span>
                            </div>
                            <div>{{ userDetails.biography }}</div>
                            <div class="tw-flex tw-items-center tw-space-x-4">
                                <a class="tw-no-underline" :href="userDetails.website">{{ userDetails.website }}</a>
                                <neo-analyse v-if="userDetails.website" source="url" :value="userDetails.website" />
                            </div>
                            <div class="tw-flex tw-items-center tw-space-x-8 tw-mt-2">
                                <div v-if="userDetails.is_private" class="tw-flex tw-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5 tw-text-gray-500 tw-mr-2" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
                                        <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                                    </svg>
                                    Private Account
                                </div>
                                <div v-if="userDetails.is_business" class="tw-flex tw-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5 tw-text-yellow-700 tw-mr-2" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd" />
                                        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
                                    </svg>
                                    Business Account
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import neoSwitch from "@/components/toggle-switch";
import NeoAnalyse from "@/components/analyse";
import {getBackgroundStatus} from "@/utils/monitoringTools.js";
import axios from "../../../../axios/osint.js";
import {DateTime} from "luxon";
const ImagePlaceholder = () => import("@/components/image-placeholder");

export default {
    components: {
        neoSwitch,
        NeoAnalyse,
        ImagePlaceholder,
    },

    props: {
        userDetails: {
            type: Object,
            required: true,
        },
        isSearchClicked: {
            type: Boolean,
        },
        isMonitoringTab: {
            type: Boolean,
        },
        index: {
            type: Number,
        },
    },

    methods: {
        getBackgroundStatus,

        async getURL(url, id) {
            let api = "";
            if (process.env.NODE_ENV == "development") api = `instagram/image/${btoa(url)}`;
            else api = `instagram/image/${btoa(url)}`;

            let image = "";
            image = await axios.get(api);
            if (image?.data?.data && id) document.getElementById(id).src = image.data.data;
        },

        handleMonitoring() {
            this.$emit("handleMonitoring", true, this.index);
        },

        handleDone($event) {
            this.$emit("handleDone", true, $event, this.index);
        },
        getFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
    },
};
</script>

<style></style>
