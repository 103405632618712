import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import analysisCard from "../ToolsCard.vue";
import moment from "moment";
import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import Tab from "@/components/tab";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import DropdownAnalysis from "@/components/dropdown-analysis";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import Pagination from "@/components/pagination";
import axios from "../../../../axios/osint.js";
import blackBoxAxios from "@/axios";
import {mapGetters, mapMutations, mapActions} from "vuex";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "@/utils/functions";
import NeoAnalyse from "@/components/analyse";
import JsonCSV from "vue-json-csv";
import {Carousel, Slide} from "vue-carousel";
import {DateTime} from "luxon";
import NlpAnalysisHighlighting from "@/components/nlp-analysis-highlighting";
import {monitoringSearchData, getBackgroundStatus, getResultsID} from "@/utils/monitoringTools.js";
import toolsHelper from "@/mixins/toolsHelper";
import neoSwitch from "@/components/toggle-switch";
import instagramCard from "./InstagramCard.vue";
const ImagePlaceholder = () => import("@/components/image-placeholder");
import toolsDisclaimer from "@/components/tools-disclaimer";

export default {
    name: "neo-instagram",
    mixins: [toolsHelper],
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tab": Tab,
        "neo-tabs-switch": NeoTabsSwitch,
        "neo-pagination": Pagination,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        "neo-dropdown-analysis": DropdownAnalysis,
        VueMultiselect,
        "analysis-card": analysisCard,
        NeoAnalyse,
        "download-csv": JsonCSV,
        Carousel,
        Slide,
        neoSwitch,
        "nlp-analysis-highlighting": NlpAnalysisHighlighting,
        instagramCard,
        ImagePlaceholder,
        toolsDisclaimer,
    },
    props: {
        osintGraphDrawerOpen: {
            type: Boolean,
            default: false,
        },
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },

        toolSearchQueries: Array,
    },
    data() {
        return {
            inputOptions: [
                {
                    group: "Field Type",
                    categories: ["Username", "Query"],
                },
            ],
            inputValue: "Username",
            instagramUsers: [],
            loaderController: {
                details: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
                followers: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
                friends: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
                stories: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
                highlights: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            },
            postsNext: null,
            friendsNext: null,
            alertMessage: {
                success: null,
                error: null,
            },
            isSearchClicked: false,
            case_id: "",
            userDetails: {},
            allStories: [],
            allHighlights: [],
            allFollowers: [],
            allFriends: [],
            allFriendsList: [],
            filters: {},
            followerHeaders: [
                {
                    text: "",
                    value: "profile",
                },
            ],
            friendsHeaders: [
                {
                    text: "",
                    value: "profile",
                },
            ],
            itemKey: "id",
            followersItemKey: "pk",
            searchCaseFilter: "",
            followerFilter: "",
            friendsFilter: "",
            selectable: true,
            friendsNextButton: false,
            hasAction: false,
            showResult: false,
            pagination: {
                followers: {
                    page: 1,
                    perPage: 200,
                    total_page: 1,
                    next: null,
                },
                friends: {
                    page: 1,
                    perPage: 200,
                    total_page: 1,
                    next: null,
                },
                stories: {
                    page: 1,
                    perPage: 100,
                    total_page: 1,
                    next: null,
                },
                highlights: {
                    page: 1,
                    perPage: 20,
                    total_page: 1,
                    next: null,
                },
            },
            csvData: {
                stories: [],
                followers: [],
                friends: [],
            },
            searchKeyword: "",
            query: "",
            selectedTab: "details",
            selectedItem: null,
            tabs: [
                {
                    name: "Details",
                    value: "details",
                },
                {
                    name: "Posts",
                    value: "posts",
                },
            ],
            querySearchRes: [],
            triggerRoute: true,
            searchFollowers: "",
            searchFriends: "",
            selectVal: "",
            tabName: "",
            userDetailsMonitoring: [],
            friendsLength: 0,
            isLoading: false,
            request_id: "",
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getResearchData", "getAllToolsData", "getAllMonitoringData", "getCaseUsernames", "getSelectedToolQuery", "getMonitoringDateFilter", "getProduct"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },

        csvFields() {
            return {
                stories: ["id", "images", "caption", "created_time", "figures", "post_url", "comments"],
                followers: ["username", "full_name", "profile_pic_url", "is_verified"],
                friends: ["username", "full_name", "profile_pic_url", "is_verified"],
            };
        },

        highlights() {
            let data = [];
            const start = (this.pagination.highlights.page - 1) * this.pagination.highlights.perPage;
            const end = this.pagination.highlights.page * this.pagination.highlights.perPage - 1;
            this.allHighlights.forEach((highlights, index) => {
                if (start <= index && index <= end) {
                    data.push(highlights);
                }
            });
            return data;
        },

        stories() {
            let data = [];
            // const start = (this.pagination.stories.page - 1) * this.pagination.stories.perPage;
            // const end = this.pagination.stories.page * this.pagination.stories.perPage - 1;
            this.allStories.forEach((stories, index) => {
                // if (start <= index && index <= end) {
                data.push(stories);
                // }
            });
            return data;
        },

        followers() {
            let data = [];
            const start = (this.pagination.followers.page - 1) * this.pagination.followers.perPage;
            const end = this.pagination.followers.page * this.pagination.followers.perPage - 1;
            this.allFollowers.forEach((follower, index) => {
                if (start <= index && index <= end) {
                    data.push(follower);
                }
            });
            data = data.filter((n) => n.full_name?.toLowerCase().includes(this.searchFollowers.toLowerCase()) || n.username?.toLowerCase().includes(this.searchFollowers.toLowerCase()));
            return data;
        },

        friends() {
            let data = [];
            const start = (this.pagination.friends.page - 1) * this.pagination.friends.perPage;
            const end = this.pagination.friends.page * this.pagination.friends.perPage - 1;
            this.allFriends.forEach((friend, index) => {
                if (start <= index && index <= end) {
                    data.push(friend);
                }
            });
            data = data.filter((n) => n.full_name?.toLowerCase().includes(this.searchFriends.toLowerCase()) || n.username?.toLowerCase().includes(this.searchFriends.toLowerCase()));
            return data;
        },
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        checkMarkAllDone() {
            if (this.querySearchRes && this.querySearchRes.length) return this.querySearchRes.every((result) => result.changes_viewed);
            else return false;
        },
        checkMarkAllDoneUsers() {
            if (this.userDetailsMonitoring && this.userDetailsMonitoring.length) return this.userDetailsMonitoring.every((result) => result.changes_viewed);
            else return false;
        },
        instaCond() {
            return this.inputValue === "Username" && this.isSearchClicked && this.showResult && (this.userDetails || this.stories.length) && !this.isMonitoringTab;
        },
        instaMonitoringCond() {
            return this.inputValue === "Username" && this.isSearchClicked && this.showResult && this.isMonitoringTab && this.userDetailsMonitoring && this.userDetailsMonitoring.length;
        },
    },
    watch: {
        // userDetails() {
        //     if (this.userDetails) {
        //         this.$emit("searchResult", { data: this.userDetails, source: "instagram", searchType: "username" });
        //     }
        // },
        // allFollowers() {
        //     if (this.allFollowers.length > 0) {
        //         this.$emit("searchResult", { data: this.allFollowers, source: "instagram", searchType: "followers" });
        //     }
        // },
        // allFriends() {
        //     if (this.allFriends.length > 0) {
        //         this.$emit("searchResult", { data: this.allFriends, source: "instagram", searchType: "friends" });
        //     }
        // },
        "$route.query.q"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
            }
        },

        // query() {
        //     this.showResult = false;
        // },

        // selectedItem: {
        //     handler(val) {
        //         this.showResult = false;
        //     },
        //     deep: true,
        // },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        this.tabName = this.$route.name;
        const dataRes = this.getToolsData(this.tabName, "instagram-tool");
        if (dataRes) this.redirectToQuery(dataRes);
        this.getToolData();
    },
    beforeDestroy() {
        this.setToolsData(this.tabName, "instagram-tool");
    },
    methods: {
        monitoringSearchData,
        getBackgroundStatus,
        getResultsID,
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAllCaseUsernames"]),
        async getToolData() {
            this.instagramUsers = await this.getDropdownData("username");
        },

        formatTS(ts) {
            return DateTime.fromSeconds(ts).toFormat("EEE, dd LLL yyyy, hh:mm a");
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },
        encodeURL(url) {
            if (process.env.NODE_ENV == "development") return `${process.env.VUE_APP_OSINT_ENGINE_URL}/api/v1/instagram/image/${btoa(url)}`;
            else return `/osint-api/api/v1/instagram/image/${btoa(url)}`;
        },

        getOptionsList(list) {
            return [
                {
                    group: "Username",
                    categories: this[list],
                },
            ];
        },

        async getURL(url, id) {
            let api = "";
            if (process.env.NODE_ENV == "development") api = `instagram/image/${btoa(url)}`;
            else api = `instagram/image/${btoa(url)}`;

            let image = "";
            image = await axios.get(api);
            if (image?.data?.data && id && document.getElementById(id) && document.getElementById(id).src) document.getElementById(id).src = image.data.data;
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "instagram", [...this.instagramUsers], data);
            allChecked = [...this.instagramUsers]?.length ? ([...this.instagramUsers].findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
        },
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
        },

        updateFieldType() {
            // this.handleClearAll();
            this.showResult = false;
            this.isSearchClicked = false;
            this.selectVal = "";
            this.searchKeyword = "";
            this.query = "";
            this.selectedItem = null;
        },

        handleClearAll() {
            this.searchKeyword = "";
            this.query = "";
            this.allFollowers = [];
            this.allFriends = [];
            this.allStories = [];
            this.allHighlights = [];
            this.csvData = {
                stories: [],
                followers: [],
                friends: [],
            };
        },

        paginateFollowers(pageNo) {
            this.pagination.followers.page = pageNo;
            if (pageNo === this.pagination.followers.total_page && this.pagination.followers.next) {
                this.getFollowers(true);
            }
            document
                .getElementsByClassName("instagram_followers")[0]
                .querySelector("tbody")
                .scrollTo(0, 0);
        },

        paginateFriends(pageNo) {
            this.pagination.friends.page = pageNo;
            if (pageNo === this.pagination.friends.total_page && this.pagination.friends.next) {
                this.getFriends(true);
            }
            document
                .getElementsByClassName("instagram_friends")[0]
                .querySelector("tbody")
                .scrollTo(0, 0);
        },

        getCount(count) {
            return count >= 1000 ? (count / 1000).toFixed(1) + "K" : count;
        },

        getTime(item) {
            let date = new Date(item.created_at);
            return date ? date.getDate() + "/" + (parseInt(date.getMonth(), 10) + 1) + "/" + date.getFullYear() : "";
        },

        getJoined(item) {
            let date = new Date(item.created_at);
            // let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            return date ? moment(date).format("DD MMMM YYYY, HH:mm") : "";
            // return date ? date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear() : '' + " " + date.getHours() + ":" + date.getMinutes() ;
        },

        setInput() {
            this.searchKeyword = this.selectedItem.value;
            // this.search();
        },

        setTheCustomInput(value) {
            this.searchKeyword = value;
            this.selectedItem = {
                value: value,
            };
            // this.search();
        },
        manualSearch() {
            this.triggerRoute = false;
            this.search();
        },
        async search() {
            this.isSearchClicked = true;
            this.showResult = false;
            this.pagination.friends.next = null;
            this.pagination.followers.next = null;
            if (this.inputValue === "Username") {
                if (this.searchKeyword) {
                    if (this.isMonitoringTab) {
                        await this.monitoringSearch(this.inputValue);
                        return;
                    }
                    this.$emit("searched", {data: {username: this.searchKeyword}, source: "instagram"});
                    await this.getUserDetails();
                    await this.setSearchedTweetUser(this.searchKeyword);
                    this.triggerRoute = true;
                } else {
                    this.$toast.error("Username field is required");
                }
            } else if (this.inputValue === "Query") {
                if (this.query) {
                    if (this.isMonitoringTab) {
                        await this.monitoringSearch(this.inputValue);
                        return;
                    }
                    this.$emit("searched", {data: {query: this.query}, source: "instagram"});
                    await this.getQueryDetails();
                    this.triggerRoute = true;
                } else {
                    this.$toast.error("Query field is required");
                }
            }
            this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "instagram-tool"});
        },
        async monitoringSearch(type) {
            let sources = ["instagram"];
            let values = await monitoringSearchData(this.case_id, sources, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery);
            this.showResult = true;
            if (type === "Username") {
                this.userDetailsMonitoring = values;
                // DO NOT FETCH POSTS IN MONITORING
                // if (this.userDetails && !this.userDetails.is_private) this.getStories();
            }
            if (type === "Query") {
                this.querySearchRes = values;
            }
        },

        async getQueryDetails() {
            // this.userDetails = {};
            // this.allStories = [];
            // this.allHighlights = [];
            // this.allFriends = [];
            // this.allFollowers = [];
            this.querySearchRes = [];
            this.isLoading = true;
            this.startLoader("details");
            const {data: searchedData} = await axios.post(
                "/instagram/search",
                {query: this.query},
                {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                }
            );
            if (searchedData.data?.data?.length > 0) {
                this.showResult = true;
                this.querySearchRes = searchedData.data.data;
                this.$emit("searchResult", {data: searchedData.data.data, source: "instagram", searchType: "query"});
                this.isLoading = false;
            } else {
                this.$toast.error(`No data found against this query ${this.query}`);
                this.isLoading = false;
            }
            this.request_id = searchedData?.query_id;
            this.redirectTo();
            this.resetLoader("details");
        },

        async getUserDetails() {
            // this.querySearchRes = [];
            this.isLoading = true;
            this.userDetails = {};
            this.allStories = [];
            this.allHighlights = [];
            this.allFriends = [];
            this.allFollowers = [];
            this.csvData = {
                stories: [],
                followers: [],
                friends: [],
            };
            this.startLoader("details");
            // this.userDetails = {};
            let data = {
                username: this.searchKeyword,
            };
            let url = "/instagram/user";
            if (this.inputValue === "Username") {
                data = {
                    username: this.searchKeyword,
                };
                url = "/instagram/user";
            }
            // else
            // if (this.inputValue === "Query") {
            //     data = {
            //         query: this.query,
            //     };
            //     url = "/instagram/search";
            // }

            const response = await axios.post(url, data, {
                headers: {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                },
            });
            try {
                if (response && response.data && response.data.data && response.data.code === 200) {
                    this.userDetails = response.data.data;
                    if (this.userDetails && !this.userDetails.is_private) this.getStories();
                    this.showResult = true;
                    this.isLoading = false;
                    this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "instagram-tool"});
                } else if (response && response.data && response.data.message) {
                    this.$toast.error(response.data.message);
                    this.isLoading = false;
                } else if (response) {
                    this.$toast.error("Please enter a valid input");
                    this.isLoading = false;
                }
                this.request_id = response?.data?.query_id;
                this.redirectTo();
                this.$emit("searchResult", {data: this.userDetails, source: "instagram", searchType: "username"});

                this.resetLoader("details");
            } catch (error) {
                this.resetLoader("details");
                let message = error.message;
                this.$toast.error(message);
                this.isLoading = false;
            }
        },
        async setSearchedTweetUser(searchKeyword) {
            const data = {
                value: searchKeyword,
                platform: "instagram",
                val_type: this.selectedItem?.val_type || "username",
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let dataItem = this[`instagramUsers`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[`instagramUsers`].push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },

        loadMorePosts() {
            this.isLoading = true;
            this.getStories(this.postsNext);
        },

        async getStories(isNext = null) {
            if (this.searchKeyword) {
                this.startLoader("stories");
                let data = {
                    user_id: this.userDetails.pk,
                };
                if (isNext != null) {
                    data.page = isNext;
                }

                const response = await axios.post("/instagram/feed", data, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                        "x-query-id": this.request_id,
                    },
                });
                try {
                    if (response && response.data && response.data.complete_response) {
                        if (isNext) {
                            this.allStories = this.allStories.concat(response.data.complete_response[0]?.data?.items);
                            this.isLoading = false;
                        } else {
                            this.allStories = [];
                            this.allStories = response.data.complete_response[0]?.data?.items;
                            this.isLoading = false;
                        }
                        this.csvData.stories = this.convertToCSV(this.allStories);
                        this.postsNext = response.data.has_next ? response.data?.next_page : null;
                        this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "instagram-tool"});
                    } else {
                        this.$toast.error("No feed for user.");
                    }

                    this.resetLoader("stories");
                } catch (error) {
                    this.resetLoader("stories");
                    let message = error.message;
                    this.$toast.error(message);
                }
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },

        async getHighlights(isNext) {
            if (this.searchKeyword) {
                this.startLoader("highlights");
                let data = {
                    user_id: this.userDetails.id,
                };

                const response = await axios.post("/instagram/highlights", data, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                });
                try {
                    if (response && response.data && response.data.data) {
                        if (isNext) {
                            this.allHighlights = this.allHighlights.concat(response.data.data);
                        } else {
                            this.allHighlights = [];
                            this.allHighlights = response.data.data;
                        }
                        this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "instagram-tool"});
                    } else if (response) {
                        this.$toast.error("Please enter a valid input");
                    }

                    this.resetLoader("highlights");
                } catch (error) {
                    this.resetLoader("highlights");
                    let message = error.message;
                    this.$toast.error(message);
                }
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },

        async getFollowers(refreshflag) {
            this.startLoader("followers");
            if (refreshflag) {
                this.pagination.followers.next = null;
            }
            let data = {
                user_id: this.userDetails.pk,
                page: this.pagination?.followers?.next || "",
            };
            const response = await axios.post("/instagram/followers", data, {
                headers: {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                    "x-query-id": this.request_id,
                },
            });
            try {
                if (response && response.data && response.data.data?.length) {
                    this.allFollowers = [];
                    this.allFollowers = response.data.data;
                    this.$emit("searchResult", {data: this.allFollowers, source: "instagram", searchType: "followers"});
                    this.csvData.followers = this.convertToCSV(this.allFollowers);
                    this.pagination.followers.next = response.data?.meta?.next_page;
                    this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "instagram-tool"});
                } else {
                    let message = "Something went wrong. Please try again after some time";
                    this.$toast.error("No data found");
                }

                this.resetLoader("followers");
            } catch (error) {
                this.resetLoader("followers");
                let message = error.message;
                this.$toast.error("Something went wrong. Please try again after some time");
            }
        },

        async getFriends(refreshflag) {
            // if(refreshflag ||this.allFriendsList.length == 0 || this.friendsLength == this.allFriendsList.length-1) {
            // this.friendsLength = 0;
            // this.allFriendsList = [];
            // this.friendsNextButton = false;
            // this.pagination.friends.page = 1;
            if (refreshflag) this.pagination.friends.next = null;
            this.startLoader("friends");
            let data = {
                user_id: this.userDetails.pk,
                page: this.pagination?.friends?.next || "",
            };

            const response = await axios.post("/instagram/following", data, {
                headers: {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                    "x-query-id": this.request_id,
                },
            });
            try {
                if (response && response.data && response.data.data?.length) {
                    this.allFriends = [];
                    this.allFriends = response.data.data;
                    // this.allFriendsList = response.data.data;
                    this.$emit("searchResult", {data: this.allFriends, source: "instagram", searchType: "friends"});
                    this.csvData.friends = this.convertToCSV(this.allFriends);
                    this.pagination.friends.next = response.data?.meta?.next_page;
                    // if(this.allFriendsList.length <= this.pagination.friends.perPage ) {if(this.pagination.friends.next == null) this.friendsNextButton = true};
                    this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "instagram-tool"});
                    // let data = [];
                    // const start = (this.pagination.friends.page - 1) * this.pagination.friends.perPage;
                    // const end = this.pagination.friends.page * this.pagination.friends.perPage - 1;
                    // this.allFriendsList.forEach((friend, index) => {
                    //     if (start <= index && index <= end) {
                    //         data.push(friend);

                    //     }
                    // });
                    // this.allFriends= data.filter((n) => n.full_name?.toLowerCase().includes(this.searchFriends.toLowerCase()) || n.username?.toLowerCase().includes(this.searchFriends.toLowerCase()));
                    // return data;
                } else {
                    this.$toast.error("No data found");
                }

                this.resetLoader("friends");
            } catch (error) {
                this.resetLoader("friends");
                let message = error.message;
                this.$toast.error("Something went wrong. Please try again after some time");
            }

            //  } else {
            //     let data = []
            //     this.pagination.friends.page = this.pagination.friends.page + 1;
            //     const start = (this.pagination.friends.page - 1) * this.pagination.friends.perPage;
            //     const end = (this.pagination.friends.page) * this.pagination.friends.perPage - 1;
            //     this.allFriendsList.forEach((friend, index) => {
            //         if (index >= start && index <= end) {
            //             data.push(friend);
            //             if(index == this.allFriendsList.length-1) {
            //                 this.friendsNext = this.pagination.friends.next
            //                 this.friendsLength = index;
            //             }
            //         }

            //     });
            //     this.allFriends = data.filter((n) => n.full_name?.toLowerCase().includes(this.searchFriends.toLowerCase()) || n.username?.toLowerCase().includes(this.searchFriends.toLowerCase()));
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                const type = Object.keys(selected_query?.query)[0];
                const value = Object.values(selected_query?.query)[0];
                if (type === "username") {
                    this.searchKeyword = value;
                    this.selectedItem = {
                        value: value,
                    };
                    this.inputValue = "Username";
                } else if (type === "query") {
                    this.query = value;
                    this.inputValue = "Query";
                }
                this.selectVal = value;
                if (!dataRes) this.search();
            } else {
                this.redirectTo();
            }
        },
        onSelect({value}) {
            this.selectVal = value;
            this.$refs["analysis-tool-input"].search = value;
        },

        close() {
            this.selectedItem = {
                value: this.selectVal,
            };
            this.searchKeyword = this.selectVal;
            this.$refs["analysis-tool-input"].search = this.selectVal;
        },
        handleChange(event) {
            if (event != this.selectVal) {
                this.showResult = false;
                this.isSearchClicked = false;
            }
            this.selectVal = event;
        },
        onInput() {
            this.showResult = false;
            this.isSearchClicked = false;
        },

        /// MARK ALL AS DONE
        async handleMarkAsAllDone(isUser, event) {
            // this.adverseResults.every((advRes) => advRes.api_all_data.changes_viewed);
            if (isUser) {
                this.userDetailsMonitoring.forEach((res, index) => {
                    this.userDetailsMonitoring[index].changes_viewed = event.target.checked;
                });
            } else {
                this.querySearchRes.forEach((res, index) => {
                    this.querySearchRes[index].changes_viewed = event.target.checked;
                });
            }
            this.$forceUpdate();
            await blackBoxAxios.post("/data-monitoring/mark-monitered-data-visited/query/all", {
                sources: ["instagram-tool"],
                mark_all_as_visited: event.target.checked,
                query_id: this.getSelectedToolQuery.query_id,
            });
        },
        async handleMonitoring(userDetail = false, index) {
            let status, card_id, sources;
            sources = "instagram-tool";
            if (userDetail) {
                this.userDetailsMonitoring[index].monitoring_status = !this.userDetailsMonitoring[index].monitoring_status;
                status = this.userDetailsMonitoring[index].monitoring_status;
                card_id = this.userDetailsMonitoring[index].doc_id;
            } else {
                this.querySearchRes[index].monitoring_status = !this.querySearchRes[index].monitoring_status;
                status = this.querySearchRes[index].monitoring_status;
                card_id = this.querySearchRes[index].doc_id;
            }
            this.$forceUpdate();
            await blackBoxAxios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.$route.params.id,
                query: this.getSelectedToolQuery,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id: card_id,
                card_source: sources,
                status: status ? "enabled" : "disabled",
            });
        },

        async handleDone(userDetail = false, {target}, index) {
            let doc_id;
            let sources = ["instagram-tool"];
            // console.log(userDetail,target.checked,index);
            if (userDetail) {
                this.userDetailsMonitoring[index].changes_viewed = !this.userDetailsMonitoring[index].changes_viewed;
                doc_id = this.userDetailsMonitoring[index].monitoring_id;
            } else {
                this.querySearchRes[index].changes_viewed = !this.querySearchRes[index].changes_viewed;
                doc_id = this.querySearchRes[index].monitoring_id;
            }
            this.$forceUpdate();
            await blackBoxAxios.post("/data-monitoring/mark-monitered-data-visited", {
                sources,
                doc_id: doc_id,
                visited: target.checked,
            });
        },
        getFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
    },
};
